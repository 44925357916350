import request from '@/utils/request'

const baseURL = process.env.VUE_APP_JT_API

// 查询设备-车辆绑定列表
export function listDeviceVehicle(query) {
  return request({
    url: '/deviceVehicle/list',
    baseURL,
    method: 'get',
    params: query
  })
}

// 查询设备-车辆绑定详细
export function getDeviceVehicle(id) {
  return request({
    url: '/deviceVehicle/' + id,
    baseURL,
    method: 'get'
  })
}

// 新增设备-车辆绑定
export function addDeviceVehicle(data) {
  return request({
    url: '/deviceVehicle',
    baseURL,
    method: 'post',
    data: data
  })
}

// 修改设备-车辆绑定
export function updateDeviceVehicle(data) {
  return request({
    url: '/deviceVehicle',
    baseURL,
    method: 'put',
    data: data
  })
}

// 删除设备-车辆绑定
export function delDeviceVehicle(id) {
  return request({
    url: '/deviceVehicle/' + id,
    baseURL,
    method: 'delete'
  })
}

// 导出设备-车辆绑定
export function exportDeviceVehicle(query) {
  return request({
    url: '/deviceVehicle/export',
    baseURL,
    method: 'get',
    params: query
  })
}

// 新增设备-绑定
export function bindDeviceVehicle(data) {
  return request({
    url: '/deviceVehicle/bind',
    baseURL,
    method: 'post',
    data: data
  })
}

// 解除绑定
export function unbindDeviceVehicle(data) {
  return request({
    url: '/deviceVehicle/unbind',
    baseURL,
    method: 'post',
    data: data
  })
}
